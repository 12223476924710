.review{
    display: grid;
    grid-template-columns: 50px auto;
    grid-column-gap: 15px;
}

.review__cover{
    grid-column: 1;
    grid-row: 1 / 3;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    max-width: 100%;
    object-fit: cover;
}

.review__text{
    grid-column: 1 / 3;
    margin-top: 15px;
}

.review__user{
    grid-column: 2;
}

.review__cover-avatar{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9b9da0;
}