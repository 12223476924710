.Persik {
  display: block;
  width: 30%;
  max-width: 240px;
  margin: 20px auto;
}

.quest__item{
    padding: 10px;
}

.quest-card{
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.quest-card__age{
    position: absolute;
    right: 15px;
    top: 15px;
    padding: 5px 10px;
    border-radius: 20px;
    background: #9b59b5;
    font-size: 14px;
    color: #ffffff;
}

.quest-card__cover{
    height: 150px;
    width: 100%;
    object-fit: cover;
}

.quest-card__meta{
    padding: 10px;
    background: #ffffff;
}

.quest-page__title{
    margin-top: 20px;
    font-size: 1.5rem;
}

.quest-page__slider .flickity-viewport{
    overflow: hidden;
    border-radius: 5px;
}

.quest-page__slider .flickity-enabled{
    outline: none;
}

.quest-page__slide{
    height: 200px;
    background: #000000;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
}

.quest-page__slide img{
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.quest-card__item-text{
    font-size: 14px;
}

.quest-card__list{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    margin-bottom: 15px;
}

.quest-card__item{
    display: flex;
    align-items: center;
}

.quest-card__item-icon{
    margin-right: 10px;
}

.quest-card__item-icon svg{
    color: #a1a1a1;
}

.quest-card__meta-title{
    font-size: 16px;
    color: #000;
    margin-bottom: 15px;
}

.quest-card__meta-address{
    font-size: 14px;
    color: #686868;
    margin-bottom: 15px;
}

.quest-card__meta-priceval{
    font-size: 20px;
    font-weight: 700;
}

.quest-card__price{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    align-items: center;
}

.quest-card__meta-price{
    font-size: 16px;
    font-weight: 700;
    color: #333333;
}
.Search--ios .Search__width{
    width: auto !important;
    width: initial !important;
}

.categories{
    overflow: hidden;
}

.categories .flickity-enabled{
    outline: none;
}

.categories__item{
    height: 40px;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    font-weight: bold;
    font-size: 15px;
    color: #1a1a1a;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    margin-right: 5px;
    white-space: nowrap;
    cursor: pointer;
    -webkit-transition: background-color .2s;
    transition: background-color .2s;
    outline: none;
    touch-action: pan-x;
}

.categories__item:active{
    background: #eeeeee;
}

.categories__item-count{
    color: #5181b8;
    margin-left: 10px;
}

.categories__item_active{
    background: #5181b8;
    color: #ffffff;
}

.categories__item_active:hover{
    background: #4d6da0;
    color: #ffffff;
}

.categories__item_active .categories__item-count{
    color: #ffffff;
}
.allquests__empty{
     border-radius: 3px;
     text-align: center;
     padding: 1rem 1.2rem;
     background: #ffeec8;
     font-family: 'Open Sans', sans-serif;
     font-size: 14px;
     color: #b16604;
     display: block;
     width: 100%;
     box-sizing: border-box;
}

.quest__list{
     height: 100%;
     display: grid;
     grid-template-columns: 1fr 1fr;
     grid-gap: 10px;
}

.allquests__none{
     grid-column: 1 / 3;
}

@media (max-width: 680px) {
     .quest__list{
          grid-template-columns: 1fr;
     }
}
.quest-page{
    padding-bottom: 60px;
}

.quest-page__cover{
    height: 150px;
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 15px;
}

.quest-card__phone{
    text-decoration: none;
}

.quest-card__phone-link{
    text-decoration: underline;
    color: #5181b8;
}

.quest-page__cover-src{
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 5px;
    z-index: -1;
    overflow: hidden;
}

.quest-page__cover-layout{
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

.quest-page__cover-name{
    font-size: 25px;
    color: #ffffff;
}

.quest-page__slider{
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flickity-page-dots{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 20px;
}

.flickity-page-dots .dot{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #fff;
    margin-right: 15px;
}

.flickity-page-dots .dot:last-child{
    margin-right: 0;
}

.flickity-page-dots .is-selected{
    background: #5181B8;
}

.quest-page__slider_clear .flickity-page-dots{
    display: none;
}

.quest-page__meta{
    display: grid;
    grid-template-columns: 1fr;
}

.quest-page__brone{
    background: #ebedf0;
}
.review{
    display: grid;
    grid-template-columns: 50px auto;
    grid-column-gap: 15px;
}

.review__cover{
    grid-column: 1;
    grid-row: 1 / 3;
    border-radius: 50%;
    max-width: 100%;
    object-fit: cover;
}

.review__text{
    grid-column: 1 / 3;
    margin-top: 15px;
}

.review__user{
    grid-column: 2;
}

.review__cover-avatar{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9b9da0;
}
.reviews__empty{
    border-radius: 3px;
    text-align: center;
    padding: 1rem 1.2rem;
    background: #ffeec8;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #b16604;
    display: block;
    width: 100%;
    box-sizing: border-box;
}
.warning__alert{
    border-radius: 3px;
    text-align: center;
    padding: 1rem 1.2rem;
    background: #ffeec8;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #b16604;
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.map__overlay{
    overflow: hidden;
}

.categories__item {
    touch-action: pan-y;
}
