.quest__item{
    padding: 10px;
}

.quest-card{
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.quest-card__age{
    position: absolute;
    right: 15px;
    top: 15px;
    padding: 5px 10px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    background: #9b59b5;
    font-size: 14px;
    color: #ffffff;
}

.quest-card__cover{
    height: 150px;
    width: 100%;
    object-fit: cover;
}

.quest-card__meta{
    padding: 10px;
    background: #ffffff;
}

.quest-page__title{
    margin-top: 20px;
    font-size: 1.5rem;
}

.quest-page__slider .flickity-viewport{
    overflow: hidden;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.quest-page__slider .flickity-enabled{
    outline: none;
}

.quest-page__slide{
    height: 200px;
    background: #000000;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
}

.quest-page__slide img{
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.quest-card__item-text{
    font-size: 14px;
}

.quest-card__list{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    margin-bottom: 15px;
}

.quest-card__item{
    display: flex;
    align-items: center;
}

.quest-card__item-icon{
    margin-right: 10px;
}

.quest-card__item-icon svg{
    color: #a1a1a1;
}

.quest-card__meta-title{
    font-size: 16px;
    color: #000;
    margin-bottom: 15px;
}

.quest-card__meta-address{
    font-size: 14px;
    color: #686868;
    margin-bottom: 15px;
}

.quest-card__meta-priceval{
    font-size: 20px;
    font-weight: 700;
}

.quest-card__price{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    align-items: center;
}

.quest-card__meta-price{
    font-size: 16px;
    font-weight: 700;
    color: #333333;
}