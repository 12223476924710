.warning__alert{
    border-radius: 3px;
    text-align: center;
    padding: 1rem 1.2rem;
    background: #ffeec8;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #b16604;
    display: block;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.map__overlay{
    overflow: hidden;
}

.categories__item {
    touch-action: pan-y;
}