.Search--ios .Search__width{
    width: initial !important;
}

.categories{
    overflow: hidden;
}

.categories .flickity-enabled{
    outline: none;
}

.categories__item{
    height: 40px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    font-weight: bold;
    font-size: 15px;
    color: #1a1a1a;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    margin-right: 5px;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color .2s;
    outline: none;
    touch-action: pan-x;
}

.categories__item:active{
    background: #eeeeee;
}

.categories__item-count{
    color: #5181b8;
    margin-left: 10px;
}

.categories__item_active{
    background: #5181b8;
    color: #ffffff;
}

.categories__item_active:hover{
    background: #4d6da0;
    color: #ffffff;
}

.categories__item_active .categories__item-count{
    color: #ffffff;
}