.quest-page{
    padding-bottom: 60px;
}

.quest-page__cover{
    height: 150px;
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 15px;
}

.quest-card__phone{
    text-decoration: none;
}

.quest-card__phone-link{
    text-decoration: underline;
    color: #5181b8;
}

.quest-page__cover-src{
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    z-index: -1;
    overflow: hidden;
}

.quest-page__cover-layout{
    background: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

.quest-page__cover-name{
    font-size: 25px;
    color: #ffffff;
}

.quest-page__slider{
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.flickity-page-dots{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    bottom: 20px;
}

.flickity-page-dots .dot{
    width: 10px;
    height: 10px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: #fff;
    margin-right: 15px;
}

.flickity-page-dots .dot:last-child{
    margin-right: 0;
}

.flickity-page-dots .is-selected{
    background: #5181B8;
}

.quest-page__slider_clear .flickity-page-dots{
    display: none;
}

.quest-page__meta{
    display: grid;
    grid-template-columns: 1fr;
}

.quest-page__brone{
    background: #ebedf0;
}