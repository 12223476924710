.allquests__empty{
     border-radius: 3px;
     text-align: center;
     padding: 1rem 1.2rem;
     background: #ffeec8;
     font-family: 'Open Sans', sans-serif;
     font-size: 14px;
     color: #b16604;
     display: block;
     width: 100%;
     -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
     box-sizing: border-box;
}

.quest__list{
     height: 100%;
     display: grid;
     grid-template-columns: 1fr 1fr;
     grid-gap: 10px;
}

.allquests__none{
     grid-column: 1 / 3;
}

@media (max-width: 680px) {
     .quest__list{
          grid-template-columns: 1fr;
     }
}